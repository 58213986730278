import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 94",
  week: "Semana 14",
  day: "15",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-15",
  path: "/cronologia/semana-14#dia-15-jun/",
};

const Day94 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.751 casos mediante pruebas PCR. En ese mismo
          periodo, 94 personas han requerido hospitalización (5,4% de los
          diagnósticos), de los que tres han sido ingresos en la UCI, y se han
          producido 25 fallecimientos.
        </ModText>
        <ModText>
          A partir hoy, 15 de junio, más del 70% de la población española podrá
          retomar las actividades recogidas en la fase 3 del Plan de
          desescalada.
        </ModText>
        <ModText>
          Los nuevos territorios que pasan a la Fase 3 son los siguientes:
        </ModText>

        <ModDotList>
          Castilla-La Mancha: Ciudad Real, Toledo y Albacete
        </ModDotList>
        <ModDotList>
          Castilla y León: León, Zamora, Palencia, Valladolid y Burgos
        </ModDotList>
        <ModDotList>Cataluña: Girona y Catalunya Central </ModDotList>
        <ModDotList>Comunitat Valenciana</ModDotList>
        <ModDotList>Ceuta</ModDotList>
        <ModText>
          Únicamente <strong>permanecerán en fase 2</strong> la{" "}
          <strong>Comunidad Autónoma de Madrid</strong>, las provincias
          castellanoleonesas de{" "}
          <strong>Salamanca, Segovia, Ávila y Soria</strong>, y las zonas
          sanitarias de <strong>Barcelona</strong>, Metropolitana Norte y
          Metropolitana Sur, y la zona sanitaria de <strong>Lleida</strong>.
          Galicia, por su parte, pasa a la fase de Nueva Normalidad.
        </ModText>
        <ModText>
          Hoy también comienza el plazo para solicitar el{" "}
          <InlineLink link="http://www.seg-social.es/wps/portal/wss/internet/Trabajadores/PrestacionesPensionesTrabajadores/65850d68-8d06-4645-bde7-05374ee42ac7">
            Ingreso Mínimo Vital
          </InlineLink>
          , que deberá realizarse a través de la Sede Electrónica de la
          Seguridad Social y, cuando la situación sanitaria lo permita, también
          se podrá efectuar en los Centros de Atención e Información de la
          Seguridad Social (CAISS).
        </ModText>
        <ModImage
          src="/images/svg/05_jun_telefonos.svg"
          alt="contacto ingreso mínimo vital covid 19"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day94;
